
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.growable-div {
    height: var(--remaining-height);
}

.app .all-routes {
    flex-grow: 1;
}

.myTypist {
    font-size: 16px;
    color: red;
}

.k-container {
    //min-height: 100vh;
    max-height: calc(100vh - 140px);
}

.k-container-products {
    //min-height: 100vh;
    max-height: calc(100vh - 300px);
}

.k-container-products-container {
    //min-height: 100vh;
    max-height: calc(100vh - 340px);
}

.k-overflow-h {
    overflow: hidden;
}

.k-overflow-a {
    overflow: auto;
}

.notifications-active-badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;
    
    padding: 1px 3px;
    font-size: 10px;
    
    position: absolute; /* Position the badge within the relatively positioned button */
    top: 0;
    right: 0;
}

.navbar-sections {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.privacyPol1 {
    font-size: 12px;
    color: rgb(126, 126, 126);
}

.k-bord-col-trans {
  border-color: transparent!important;
}

.color-white {
    color: #ffffff;
}

.color-MD {
    color: #A60086;
}

.color-DT {
    color: #a69000;
}

.color-RS {
    color: #1F618D;
}

.color-PT {
    color: #76448A;
}

.color-MA {
    color: #CB4335;
}

.color-VC {
    color: #73C6B6;
}

.color-MS {
    color: #27AE60;
}

.color-NF {
    color: #bac178;
}

.color-DA {
    color: #3F4055;
}

.color-DE {
    color: #B7950B;
}

.color-SH {
    color: #717D7E;
}

.color-black {
    color: black
}

.color-lightText {
    color: #a8a8a8!important;
}

.color-lighterText {
    color: #dddada!important;
}

.color-lightHeader {
    color: #8e8e8e!important;
}

.color-transparent {
    color: transparent;
}

.k-prod-start {
    color: #1c8f35;
}

.k-prod-stop {
    color: #8f1c1c;
}

.k-prod-pause {
    color: #8f611c;
}

.k-prod-terminate {
    color: #000000;
}

.k-light-blue {
    color: #3f94f0
}

.txt-bold {
    font-weight: 900!important;
}

.k-txt-bold-hvr:hover {
    font-weight: 900!important;
}

.txt-weight-100 {
    font-weight: 100!important;
}

.txt-weight-200 {
    font-weight: 200!important;
}

.txt-weight-400 {
    font-weight: 400!important;
}

.txt-weight-500 {
    font-weight: 500!important;
}

.txt-weight-600 {
    font-weight: 600!important;
}

.txt-weight-700 {
    font-weight: 700!important;
}

.txt-weight-800 {
    font-weight: 800!important;
}

.txt-weight-900 {
    font-weight: 900!important;
}

.txt-light {
    font-weight:lighter!important;
}

.txt-8 {
    font-size: 8px!important;
}

.txt-10 {
    font-size: 10px!important;
}

.txt-11 {
    font-size: 11px!important;
}

.txt-12 {
    font-size: 12px!important;
}

.txt-13 {
    font-size: 13px!important;
}

.txt-14 {
    font-size: 14px!important;
}

.txt-15 {
    font-size: 15px!important;
}

.txt-16 {
    font-size: 16px!important;
}

.txt-17 {
    font-size: 17px!important;
}

.txt-18 {
    font-size: 18px!important;
}

.txt-20 {
    font-size: 20px!important;
}

.txt-25 {
    font-size: 25px!important;
}

.txt-30 {
    font-size: 30px!important;
}

.txt-40 {
    font-size: 40px!important;
}

.k-z-ind-m-10 {
    z-index: -10;
}

.k-z-ind-10 {
    z-index: 10;
}

.k-z-ind-9999 {
    z-index: 9999;
}

.k-z-ind-99999 {
    z-index: 99999;
}

.k-z-ind-999995 {
    z-index: 999995;
}

.k-z-ind-999996 {
    z-index: 999996;
}

.k-z-ind-999997 {
    z-index: 999997;
}

.k-z-ind-999998 {
    z-index: 999998;
}

.k-z-ind-999999 {
    z-index: 999999;
}

.k-z-ind-9999999 {
    z-index: 9999999;
}
.k-z-ind-popup {
    z-index: 999999999;
}

.footerText {
    font-size: 13px;
    color: rgb(126, 126, 126);
}

.k-align-content-center {
    align-content: center;
}

.align-items-end {
    align-items: end;
}

.k-pos-abs {
    position: 'absolute'
}

.k-mb-02r {
    margin-bottom: 0.2rem!important;
}


.b-rad-2 {
    border-radius: 2px;
}

.b-rad-5 {
    border-radius: 5px;
}

.b-rad-10 {
    border-radius: 10px;
}

.b-rad-20 {
    border-radius: 20px;
}

.b-rad-t-5 {
    border-radius: 5px 5px 0px 0px;
}

.b-rad-tr-5 {
    border-radius: 5px 0px 0px 0px;
}

.b-rad-br-5 {
    border-radius: 0px 0px 0px 5px;
}

.b-rad-b-5 {
    border-radius: 0px 0px 5px 5px;
}

.k-border-none {
    border: none;
}

.k-border-grey {
    border: 0.5px solid #b7b6b6!important;
}

.k-border-top-black {
    border-top: 1px solid #000;
}

.k-border-top-grey {
    border: 0.5px solid #b7b6b6!important;
}

.k-btn-submit-col {
    background-color: #0052CC;
}

.k-bg-btn {
    background-color: #F8F9FA;
}

.k-bg-btn-dark {
    background-color: #e5e5e5;
}

.k-bg-orgBu {
    background-color: #83bbf7;
}

.k-bg-selected {
    background-color: #b4d2f1;
}

.k-bg-selected-light {
    background-color: #e3eefa;
}

.k-bg-submenu {
    background-color: #9ec4ea;
}

.k-bg-white {
    background-color: white;
}

.k-bg-trans {
    background-color: transparent!important;
}

.k-bg-menubar {
    background-color: rgb(168, 168, 168);
}

.k-border-grey-bg {
    background-color: #f3f4f7;
}

.k-menu-grey-bg {
    background-color: #f0f0f2;
}

.k-menu-grey-two-bg {
    background-color: #f4f5f7;
}

.k-menu-white-bg {
    background-color: #ffffff;
}

.k-content-cornsilk-bg {
    background-color: #fff8dc;
}

.k-mt-minus-5 {
    margin-top: -5px!important;
}

.k-mt-minus-8 {
    margin-top: -8px!important;
}

.k-mt-minus-10 {
    margin-top: -10px!important;
}

.k-mt-minus-15 {
    margin-top: -15px!important;
}

.k-mt-minus-20 {
    margin-top: -20px!important;
}

.k-pos-rel {
    position: relative;
}

.k-hover:hover {
    cursor: pointer;
    transition: background-color 0.5s ease;
    background-color: #e7e7e7;
}

.k-hover-element .hover-content {
    display: none;
}

.k-hover-element:hover .hover-content {
    display: block;
}

.k-hover-grey-bg:hover {
    transition: background-color 0.5s ease;
    background-color: #f3f4f7!important;
}

.k-hover-blue-bg:hover {
    transition: background-color 0.5s ease;
    background-color: #8ca4ea!important;
}

.k-hover-white-bg:hover {
    transition: background-color 0.5s ease;
    background-color: #ffffff!important;
}

.k-pointer:hover {
    cursor: pointer;
}

.k-brd-l-trans {
    border-left-color: transparent!important;
}

.k-brd-r-trans {
    border-right-color: transparent!important;
}

.k-disp-block {
    display: block;
}

.k-disp-flex {
    display: flex;
}

.k-grab:hover {
    cursor: grab;
}

.k-grab:active {
    cursor: grabbing;
} 

.navbar-transition {
    transition: background-color 0.5s ease;
  }

.k-w-1-7rem {
    width: 1.7rem;
}



.k-min-w-35 {
    min-width: 35px;
}

.k-min-w-45 {
    min-width: 45px;
}

.k-min-w-103 {
    min-width: 103px;
}

.k-min-w-120 {
    min-width: 120px;
}

.k-min-w-130 {
    min-width: 130px;
}

.k-min-w-140 {
    min-width: 140px;
}

.k-min-w-150 {
    min-width: 150px;
}

.k-min-w-160 {
    min-width: 160px;
}

.k-min-w-170 {
    min-width: 170px;
}

.k-min-w-180 {
    min-width: 180px;
}

.k-min-w-190 {
    min-width: 190px;
}

.k-min-w-200 {
    min-width: 200px;
}

.k-min-w-210 {
    min-width: 210px;
}

.k-min-w-220 {
    min-width: 220px;
}

.k-min-w-225 {
    min-width: 225px;
}

.k-min-w-250 {
    min-width: 250px;
}

.k-min-w-260 {
    min-width: 260px;
}

.k-min-w-270 {
    min-width: 270px;
}

.k-min-w-300 {
    min-width: 300px;
}

.k-min-w-400 {
    min-width: 400px;
}

.k-min-w-400 {
    min-width: 400px;
}

.k-min-w-500 {
    min-width: 500px;
}
.k-min-w-600 {
    min-width: 600px;
}

.k-mw-28 {
    max-width: 28px;
}

.k-mw-30 {
    max-width: 30px;
}

.k-mw-33 {
    max-width: 33px;
}

.k-mw-45 {
    max-width: 45px;
}

.mw-750px {
    max-width: 750px;
}

.k-ps-10 {
    padding-left: 10px !important;
}

.k-ps-11 {
    padding-left: 11px !important;
}

.k-ps-80 {
    padding-left: 80px;
}

.k-ps-2-5rem {
    padding-left: 2.5rem;
}

.k-pe-4-5rem {
    padding-right: 4.5rem;
}

.k-pe-80 {
    padding-right: 80px;
}

.k-pt-3 {
    padding-top: 3px;
}

.k-pt-5 {
    padding-top: 5px;
}

.k-pt-8 {
    padding-top: 8px;
}

.k-pt-10 {
    padding-top: 10px;
}

.k-pt-12 {
    padding-top: 12px;
}

.k-scale-80 {
    scale: 0.8;
}

.k-scale-70 {
    scale: 0.7;
}

.k-h-1-7rem {
    height: 1.7rem;
}

.k-mh-10 {
    height: 10px;
}

.k-mh-12 {
    height: 12px;
}

.k-mh-13 {
    height: 13px;
}

.k-mh-15 {
    height: 15px;
}

.k-mh-18 {
    height: 18px;
}

.k-mh-20 {
    height: 20px;
}

.k-mh-25 {
    height: 25px;
}

.k-mh-30 {
    height: 30px;
}

.k-mh-35 {
    height: 35px;
}

.k-mh-40 {
    height: 40px;
}

.k-mh-45 {
    height: 45px;
}

.k-mh-50 {
    height: 50px;
}

.k-mh-55 {
    height: 55px;
}

.k-mh-60 {
    height: 60px;
}

.k-mh-70 {
    height: 70px;
}

.k-mh-80 {
    height: 80px;
}

.k-mh-100 {
    height: 100px;
}

.k-mh-150 {
    height: 150px;
}

.k-mh-250 {
    height: 250px;
}

.k-h-2 {
    height: 2px;
}

.k-h-5 {
    height: 5px;
}

.k-h-10 {
    height: 10px;
}

.k-h-15 {
    height: 15px;
}

.k-h-18 {
    height: 18px;
}

.k-h-20 {
    height: 20px;
}

.k-h-25 {
    height: 25px;
}

.k-h-30 {
    height: 30px;
}

.k-h-35 {
    height: 35px;
}

.k-h-38 {
    height: 38px;
}

.k-h-40 {
    height: 40px;
}

.k-h-42-5 {
    height: 42.5px;
}

.k-h-45 {
    height: 45px;
}

.k-h-50 {
    height: 50px;
}

.k-h-60 {
    height: 60px;
}

.k-h-70 {
    height: 70px;
}

.k-h-80 {
    height: 80px;
}

.k-h-100 {
    height: 100px;
}

.k-h-344 {
    height: 344px;
}

.k-h-400 {
    height: 400px;
}

.k-wh-100pc {
    min-width: 100%;
}

.k-minh-100pc {
    min-height: 100%;
}

.k-min-max-h-100pc {
    min-height: 90%;
    max-height: 90%
}

.k-minh-400px {
    min-height: 400px;
}

.k-minh-350px {
    min-height: 350px;
}

.k-hmh-400px {
    max-height: 400px;
}

.k-hmh-500px {
    max-height: 500px;
}

.k-hmh-550px {
    max-height: 550px;
}

.k-hmh-600px {
    max-height: 600px;
}

.hmh-100vh-60px {
    max-height: calc(100vh - 60px);
}

.k-h-100vh-10px {
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
}

.k-min-h-100vh {
    height: calc(90vh - 140px);
    max-height: calc(90vh - 140px);
}

.k-min-h-100vh-140px {
    height: calc(95vh - 130px);
    max-height: calc(95vh - 130px);
}

.k-min-h-90vh-100px {
    min-height: 100%;
    max-height: 100%;
}

.k-min-mh-100vh {
    height: 100vh;
    max-height: 100vh;
}

.k-fixed-wh {
    height: 864px!important;
    width: 1536px!important;
}

.k-fixed-row {
    display: flex; /* Ensures columns stay in a row */
    flex-wrap: nowrap; /* Prevents wrapping to the next line */
  }

.k-header-margin {
    margin-top: 75px;;
}

.k-mt-3 {
    margin-top: 3px!important;
}

.k-mt-20 {
    margin-top: 20px;
}

.k-mt-30 {
    margin-top: 30px;
}

.k-mt-40 {
    margin-top: 40px;
}

.k-mt-70 {
    margin-top: 70px!important;
}

.k-mt-80 {
    margin-top: 80px!important;
}

.k-mr-m-10 {
    margin-right: -10px;
}

.k-mr-m-15 {
    margin-right: -15px;
}

.k-mr-m-20 {
    margin-right: -20px;
}

.k-mr-m-25 {
    margin-right: -25px;
}

.k-mr-m-30 {
    margin-right: -30px;
}

.k-mr-m-35 {
    margin-right: -35px;
}

.k-mr-m-50 {
    margin-right: -50px;
}

.k-mr-m-70 {
    margin-right: -70px;
}

.k-mr-m-250 {
    margin-right: -250px;
}

.k-mb-m-4 {
    margin-bottom: -4px!important;
}

.k-mb-m-10 {
    margin-bottom: -10px;
}

.k-mb-m-20 {
    margin-bottom: -20px;
}

.w-100-pc {
    width: 100vh;
}

.k-w-30-pc {
    width: 30%;
}

.k-w-40-pc {
    width: 40%;
}

.k-w-50-pc {
    width: 50%;
}

.k-w-60-pc {
    width: 60%;
}

.k-w-70-pc {
    width: 70%;
}

.k-w-100-pc {
    width: 100%;
}

.k-w-30 {
    width: 30px;
}

.k-w-35 {
    width: 35px;
}

.k-w-40 {
    width: 40px;
}

.k-w-45 {
    width: 45px;
}

.k-w-50 {
    width: 50px;
}

.k-w-60 {
    width: 60px;
}

.k-w-70 {
    width: 70px;
}

.k-w-80 {
    width: 80px;
}

.k-w-100 {
    width: 100px;
}

.k-w-125 {
    width: 125px;
}

.k-w-185 {
    width: 185px;
}

.k-w-200 {
    width: 200px;
}

.k-w-220 {
    width: 220px;
}

.k-w-230 {
    width: 230px;
}

.k-w-250 {
    width: 250px;
}

.k-w-260 {
    width: 250px;
}

.k-w-274 {
    width: 274px;
}

.k-w-275 {
    width: 275px;
}

.k-w-300 {
    width: 300px;
}

.k-w-350 {
    width: 350px;
}

.k-w-400 {
    width: 400px;
}

.k-w-500 {
    width: 500px;
}

.k-w-600 {
    width: 600px;
}

.k-w-800 {
    width: 800px;
}

.k-w-850 {
    width: 850px;
}

.k-w-950 {
    width: 950px;
}

.k-w-1000 {
    width: 1000px;
}

.k-w-1100 {
    width: 1100px;
}

.k-w-100-pc {
    width: 100%;
}

.k-wmw-0 {
    width: 0px;
    max-width: 0px;
}

.k-fl-auto {
    flex: 0 0 auto;
}

.k-col-1-5 {
    flex: 0 0 auto;
    width: 12%;
}

.k-col-278px {
    width: 278px;
    max-width: 278px;
}

.k-col-280px {
    width: 280px;
    max-width: 280px;
}

.k-col-20pc {
    flex: 0 0 auto;
    width: 20%;
}

.k-col-80pc {
    flex: 0 0 auto;
    width: 80%;
}

.k-header-height {
    height: 70px
}

.k-fg-1 {
    flex-grow: 1;
}

.k-opac-10pc {
    opacity: 10%;
}

.k-opac-20pc {
    opacity: 20%;
}

.k-opac-30pc {
    opacity: 0.3;
}

.k-opac-40pc {
    opacity: 0.4;
}

.k-opac-50pc {
    opacity: 50%;
}

.k-opac-60pc {
    opacity: 50%!important;
}

.k-opac-80pc {
    opacity: 80%;
}

.k-mgn-l-8 {
    margin-left: 8px;
}

.k-mgn-l-m-20 {
    margin-left: -20px;
}

.k-mgn-t-m-5 {
    margin-top: 2px;
}

.k-dropdown-toggle {
    --bs-btn-hover-color: #3F4055;
}

.k-no-select {
    user-select: none;
}

.k-display-block {
    display: block!important;
}

.k-no-pop-arrow .popover-arrow {
    display: none !important;
}

.k-center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.k-center-content-img {
    display: flex;
    justify-content: center;
}

.k-center-content-hor-right {
    align-items: normal;
    display: flex;
    justify-content: flex-end;
}

.k-center-content-hor-right-cen {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.k-center-content-hor-right-end {
    align-items: end;
    display: flex;
    justify-content: flex-end;
}

.k-center-content-hor {
    display: flex;
    align-items: center;
}

.k-center-vert-content {
    display: flex;
    align-items: center;
}

.k-vert-align {
    vertical-align: middle;
}

.k-vert-align-top {
    vertical-align:top;
}

.k-vert-align-top-flex {
    display: flex;
    align-items: flex-start;
}

.k-center-text {
    display: inline-block;
    text-align: center;
}

.k-input {
    border: 0.5px solid rgb(222 226 210);
}

.k-outline-none {
    outline: none;
}


.resize-vertical {
    resize: vertical;
    overflow: hidden;
}

.k-reset-a-style {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits color from parent element */
    font-size: inherit; /* Inherits font size from parent element */
    font-weight: inherit; /* Inherits font weight from parent element */
    background-color: transparent; /* Ensures no background color */
}

.k-clip-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    display: inline-block;
    max-width: 99%;
}

.k-clip-text-140px {
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    display: inline-block;
    max-width: 140px;
}

.trending-searches {
    background-color: white;
    border: 1px solid rgb(39, 39, 38);
    position: absolute;
    z-index: 1000;
    width: 750px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease-out;
}

.k-topnav-search {
    border-radius: 20px 20px 10px 10px;
    border: 1px solid rgb(222 226 210); /* Example border style */
    width: 600px!important;
    min-width: 200px;
    max-width: 750px;
    height: 41.5px;
}

.k-topnav-search:focus {
    outline: none; /* Remove the default focus outline */
    /* Specify your desired border style for the focus state */
    border: 1px solid rgb(222 226 210); /* Example border style */
  }

.k-main-search {
    border-radius: 20px 20px 10px 10px;
    border: 1px solid rgb(222 226 210); /* Example border style */
    //width: 750px;
    //min-width: 400px!important;
    max-width: 800px;
    height: 41.5px;
}

.k-main-search-icon {
    padding-left: 1em;
    padding-right: 0em;
    border-radius: 20px 0px 0px 10px;
    border: 1px solid rgb(222 226 210); /* Example border style */
    height: 41.5px;
    width: 40px;
}

.k-main-search-btn {
    padding-left: 0em;
    padding-right: 1em;
    border-radius: 0px 20px 10px 0px;
    border: 1px solid rgb(222 226 210); /* Example border style */
    height: 41.5px;
    width: 33px;
}

.k-main-search-tool-icon {
    padding-left: 0em;
    padding-right: 1em;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid rgb(222 226 210); /* Example border style */
    height: 41.5px;
    width: 40px;
}

.k-main-search:focus {
    outline: none; /* Remove the default focus outline */
    /* Specify your desired border style for the focus state */
    border: 1px solid rgb(222 226 210); /* Example border style */
  }

.k-selected-prod {
    width: 910px;
    min-width: 200px;
    max-width: 910px;

    margin-top: -26px;

    background-color: white;
    
    border:1px solid rgb(222 226 210);
    border-radius: 20px 20px 20px 20px;

    z-index: 20;

    padding-left: 1em;
    padding-right: 1em;
}

.k-main-suggestions {
    
    width: 910px;
    min-width: 200px;
    max-width: 910px;

    margin-top: -26px;

    background-color: white;
    
    border:1px solid rgb(222 226 210);
    border-top: transparent;
    border-radius: 0px 0px 20px 20px;

    z-index: 20;

    padding-left: 1em;
    padding-right: 1em;
}

.k-p-8 {
    padding: 8px;
}

.k-p-20 {
    padding: 20px;
}

.card-container {
    display: flex;
    overflow-x: auto;
}
  
.card-container > * {
    flex-shrink: 0;
    min-width: 300px; /* Adjust this value to match the desired card width */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1s; /* Change 2s to the duration you want */
}

@keyframes fadeInFast {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeInFast {
    animation: fadeInFast 0.2s; 
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        height: 0%;
    }
    100% {
        opacity: 1;
        height: 100%; /* Adjust as necessary */
    }
}

.brd-end {
    border-right: 1px solid rgb(222 226 210);
}

.fadeInDown {
    animation: fadeInDown 0.2s; /* Adjust duration as necessary */
}

.b-shadow:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.k-b-shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.k-pointer-building {
    cursor: no-drop;
}

.k-pointer-timeout {
    cursor: no-drop;
}

.k-pointer-stopped {
    cursor: no-drop;
}

.k-pointer-paused {
    cursor: no-drop;
}

.k-pointer-hibernation {
    cursor: no-drop;
}

.b-shadow-running:hover {
    box-shadow: 0px 4px 8px rgba(39, 174, 96, 0.2); 
}

.b-shadow-building:hover {
    box-shadow: 0px 4px 8px rgba(255, 195, 0 , 0.2); 
}

.b-shadow-timeout:hover {
    box-shadow: 0px 4px 8px rgba(203, 67, 53, 0.2); 
}

.b-shadow-click:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.k-b-shadow-none {
    box-shadow: none;
}

.b-shadow-click:active {
    box-shadow: none;
}

.k-click:active {
    color: green; /* or any other color you'd like on click */
}

.b-shadow-on {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.b-shadow-off {
    box-shadow: none
}

.k-overflow-y-clip {
    overflow-y: clip;
}

.k-overflow-y-scroll {
    overflow-y: scroll;
}

.k-overflow-y-auto {
    overflow-y: auto;
}

.scrollable-content {
    max-height: 125px; /* Adjust based on your need */
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-content-no-mh {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-content-300 {
    max-height: 220px;
    overflow-y: auto;
  }

.scrollable-content-200 {
    max-height: 110px; /* Adjust based on your need */
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-content-200::-webkit-scrollbar {
    display: none; 
}

.scrollable-content-300::-webkit-scrollbar {
    display: none; 
}

.no-scrollbar::-webkit-scrollbar {
    display: none; 
}

.popover.k-prod-tt {
    width: 400px;
    max-width: 90%; /* Optional: to ensure the popover doesn't exceed the screen width */
}

// Resize Panels //

.k-panel-col {
    display: flex;
}

.k-panel-cent {
    display: flex;
    justify-content: center; /* For horizontal alignment */
    align-items: flex-start; /* Aligns children to the start (top) of the container */
}

.k-panel-cent-vert {
    display: flex;
    align-items: center;
}

.k-panel-centered {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-panel-background);
    border-radius: 0.5rem;
    overflow: hidden;
    font-size: 1rem;
    padding: 0.5rem;
    word-break: break-all;
  }

// End Resize Panels //

.k-grab-item:hover {
    cursor: grab;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.k-grab-item-item:active {
    cursor: grabbing;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}

.k-pointer-default {
    cursor: default!important;
}

.k-pointer-item:hover {
    cursor: pointer;
}

.k-iFrame {
    position: relative;
    width: 100%;
    height: calc(100% - 24px);
    border-radius: 0rem 0rem 0rem 0rem !important;
}




.fullscreen-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(203, 203, 203, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999; /* Set a high z-index value */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}


.k-iframe-container {
    position: relative; /* Make sure the container is positioned */
    width: 100%; /* Set container width to 100% */
    height: 100%; /* Set container height to 100% */
  
    /* Additional styling for the iframe */
  }

  .k-iframe-container iframe {
    position: absolute; /* Position the iframe within the container */
    width: 100%; /* Set iframe width to 100% of the container width */
    height: 100%; /* Set iframe height to 100% of the container height */
    border: none; /* Optional: Remove iframe border */
  }

  .k-iFrame {
    //position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0rem 0rem 0rem 0rem !important;
  }
  
  .k-iFrameHolder {
    //position: absolute;
    width: 100%;
    height:100%;
    border-radius: 1rem !important;
  }

  .cart {
    display: flex;
    width: 100%;
    min-height: 8rem;
    justify-content: center;
  }

  @keyframes flashAnimation {
    0%   { background-color: transparent; }
    50%  { background-color: rgb(186, 188, 189); }
    100% { background-color: transparent; }
  }
  
  .flash {
    animation: flashAnimation 0.5s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0); 
  }

  .modal-content {
    border: none;
  }

  .chat-container {
    //border: 1px solid #e0e0e0;
    max-width: 500px;
    height: 300px;
    max-height: 70vh;   /* Optional: Set a maximum height to limit the resizing */
    min-height: 150px;  /* Optional: Set a minimum height to ensure usability */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    resize: vertical;   /* This enables the vertical resizing */
    overflow: auto;     /* This ensures that content is scrollable if it exceeds the container's height */
}
  
  .chat-messages {
    overflow-y: auto;
    padding: 10px;
  }


  
  .chat-input {
    display: flex;
    //border-top: 1px solid #e0e0e0;
    padding: 10px;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  
  .chat-message {
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    max-width: 100%;
    word-wrap: break-word;
  }
  
  .chat-message.user {
    color: white;
    max-width: 100%;
    padding-left: 20px!important;
    align-self: flex-end;
    align-items: center;
    //display: flex;
    justify-content: flex-end;
  }

  .k-center-content-hor-right {
    align-items: normal;
    display: flex;
    justify-content: flex-end;
}

  .chat-message-bg.user {
    background-color: #78b8fc;
  }

  .chat-message-bg.bot {
    background-color: #e0e0e0;
  }
  
  .chat-message.bot {
    //background-color: #e0e0e0;
    max-width: 100%;
    padding-right: 20px!important;
    align-self: flex-start;
  }
  
#sideNavCol {
    transition: transform 0.5s ease-out, width 0.5s ease-out, padding 0.5s ease-out, margin 0.5s ease-out;
}

.slide-out {
    transform: translateX(calc(-100% + 25px));  // Slide everything to the left except for 10px
    //overflow: hidden;   // Ensure the inner content doesn't spill out
}

#mainViewport {
    transition: width 0.5s ease-out;  // Here you're transitioning the width
}

.expand {
    width: calc(100% + 280px);  // Assuming the initial width of #sideNavCol is 280px
}

#sideNavCol {
    position: relative;
    border-right: 2px solid transparent;
}

#sideNavCol:hover {
    border-right-color: #F8F9FA; /* Change #yourBorderColor to your desired color */
}

.iconOverlay {
    display: none; // Hidden by default
    position: absolute;
    right: -12px;
    top: 7%;
    width: 23px;
    height: 25px;
    background: var(--icon-url) no-repeat center/cover;
    cursor: pointer; // To indicate it's clickable
    z-index: 1; // To ensure it's above the border
    transition: transform 0.4s ease-in-out;
}

#sideNavCol:hover .iconOverlay {
    display: block;
}

#sideNavCol.slide-out {
    display: block!important;
    border-right-color: #F8F9FA!important; /* Change #yourBorderColor to your desired color */
}

#sideNavImg.rotated {
    display: block!important;
}

.iconOverlay.rotated {
    transform: rotate(180deg);
}

@keyframes fadeInElement {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in-element {
    animation-name: fadeInElement;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.k-disp-inline-tbl {
    display: inline-table;
}

.context-menu {
    position: fixed;
    background-color: white;
    //border: 1px solid #ccc;
    z-index: 1000;
  }
  .context-menu div {
    padding: 0px;
    cursor: pointer;
  }

  .k-ctrlImgLrg {
    width: 40px;
    height: 40px;
}

.k-ctrlImg {
    width: 25px;
    height: 25px;
}

.k-ctrlImgSml {
    width: 15px;
    height: 15px;
}

.k-clicked-tooltip > .tooltip-inner {
    background-color: #5c9bda !important; /* Example: Bootstrap primary color */
    color: white; /* Optional: Change the text color */
    border-radius: 2px; /* Optional: Adjust the border radius */
}

.k-clicked-tooltip > .tooltip-arrow:before {
    border-right-color: #5c9bda !important;
}
  
.dropdown-menu.k-admin-dropdown {
    --bs-dropdown-border-color: #9a9a9a;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-border-radius: 0.50rem;
}

/* Remove all styles for the button */
.icon-only-btn .dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;
  }
  
  /* Remove border and shadow when button is clicked or focused */
  .icon-only-btn .dropdown-toggle:focus,
  .icon-only-btn .dropdown-toggle:active,
  .icon-only-btn .dropdown-toggle.show {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .icon-only-btn .dropdown-menu {
    margin-left: 15px; /* Adjust the value to shift the menu to the right */
  }

  .dropdown-menu-k-custom-dropdown-menu {
    margin-top: -10px!important;
    box-shadow: 0 .8rem 1.2rem #343a4014;
  }

  .selected-menu-item {
    background-color: var(--bs-dropdown-link-hover-bg) !important;
  }


  .scrollable-menu {
    max-height: 200px;  /* Adjust as needed */
    overflow-y: auto;   /* Enable scrolling */
  }

  .hover-background-light-grey {
    transition: background-color 0.3s;
  }
  
  .hover-background-light-grey:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }

  .full-description-slide {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  }
  
  .d-flex.flex-column.border-bottom.p-2:hover .full-description-slide,
  .d-flex.flex-column.border-bottom.p-2 .full-description-slide {
    max-height: 100px; /* Adjust this height based on the description content */
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .k-message-modal {
    width: 60vw;
    max-width: 60vw;
    height: 400px;
  }

  .k-messages-list-container {
    border-right: 1px solid #ddd;
    overflow-y: auto;
    max-height: 400px;
  }
  
  .k-message-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease-in-out;
  }
  
  .k-message-item:hover {
    background-color: #f0f0f0;
  }
  
  .k-message-item.k-selected {
    background-color: #e0e0e0;
    font-weight: bold;
  }
  
  .k-message-details-container {
    padding: 20px;
    overflow-y: auto;
    max-height: 400px;
  }
  
  .k-message-details h5 {
    margin-top: 0;
  }
  
  .k-custom-close-button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  
  .k-messages-list {
    padding: 10px;
  }
  
  .k-z-ind-9999 {
    z-index: 9999999;
  }
  
// ----------- MOBILE CSS ----------- //
  .mobile-site {
    text-align: center;
    padding: 20px;
  }
  
  .mobile-header {
    background-color: #f8f9fa;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  
  .mobile-content {
    margin: 20px 0;
  }
  
  .mobile-footer {
    background-color: #f8f9fa;
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }